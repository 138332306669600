<template>
  
    <div class="z-[55] fixed p-4 left-5 top-5 right-3 bottom-5 overflow-y-auto border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-2xl rounded-xl">
        <div class="flex flex-row gap-3">
            <h6 class="mb-2">Special Offer</h6>
            <button class="ml-auto but-5 but-b top-5 right-5" @click="closeModal()">X</button>
        </div>
        <div class="flex flex-col gap-2">

            <div class="mt-2">
             <!-- New players only &mdash; available for the first 7 days! -->
             {{ Data.promo['1'].intro }}
            </div>

            <img src="@/assets/promo/p1.jpg" class="w-[80%] mx-auto rounded-md border border-slate-800">

            <!-- <div class="w-full flex-grow bg-cover p-3 " :style="{ backgroundImage: `url(${promoImage})` }">
            sdfdsf
            </div> -->
            <div class="flex flex-col gap-1">
                <div class="text-xl font-bold">{{ Data.promo['1'].name }}</div>
                <div class="text-slate-400 text-sm italic">{{ Data.promo['1'].sub }}</div>
                <div class="my-1 text-sm bg-slate-950 bg-opacity-25 border-slate-700 border rounded-md px-2 py-1 flex flex-row gap-2">
                    <div class="w-1/2">
                        <ul class=" list-disc list-inside">
                            <li ><span class="font-bold">1500</span> <img src="@/assets/pics/gem.png" class="w-4 inline-block"> Gems</li>
                            <li ><img src="@/assets/icon/vip.svg" class="w-4 h-4 inline-block mb-1" > VIP for 1 week</li>
                        </ul>
                    </div>
                    <div class="w-1/2">
                        <ul class=" list-disc list-inside">
                            <li>2X Cash + EXP <img src="@/assets/icon/boost.svg" class="w-4 h-4 inline-block mb-1" ></li>
                        </ul>
                    </div>
                </div>

                <div class="my-1 text-sm bg-slate-800 rounded-md px-2 py-1 flex flex-row gap-2">
                    <div class="w-1/2">

                        <div class="text-lg">
                            <span class="line-through mr-2 text-orange-300">2500 Stars</span><br>
                            <span class="font-bold">1250<img src="@/assets/pics/star.png" class="w-4 h-4 ml-1 mb-2 inline-block"> Stars</span>
                        </div>
                    </div>
                    <div class="w-1/2">
                        <h3>Valid Until</h3>
                        <div class="text-lg">
                            {{ Data.promo['1'].valid }}
                        </div>
                    </div>
                </div>

                <button @click="goShop()" class="but-2 but-a w-fit">Open Shop</button>

            </div>

            <div class="text-sm text-slate-400">
                {{ Data.promo['1'].left }} left

            </div>

        </div>

    </div>

</template>

<script>
import promoImage from '@/assets/promo/p1.jpg';
  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {
            promoImage,
        }
    },
    methods: {
        goShop() {
            this.$router.push({ name: 'shop' });
            this.$emit('closeModal');
        },
        closeModal() {
            this.$emit('closeModal');
        }
    },
    mounted() {
       
    },
}
</script>